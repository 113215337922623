<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>服务支持</el-breadcrumb-item>
      <el-breadcrumb-item>文章发布</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-row type="flex"
              justify="space-between"
              style="margin-bottom: 30px">
        <el-button type="primary"
                   @click="goAddPage">添加文章</el-button>
        <el-input placeholder="请输入搜索关键字"
                  v-model="queryInfo.keyword"
                  clearable
                  @clear="getShowList"
                  style="width:400px">
          <el-button slot="append"
                     icon="el-icon-search"
                     class="search-btn"
                     @click="getShowList"></el-button>
        </el-input>
      </el-row>

      <!-- 新闻列表 -->
      <el-table :data="showList"
                stripe
                style="width: 100%">
        <el-table-column prop="id"
                         label="#"
                         width="180"></el-table-column>
        <el-table-column prop="name"
                         label="标题"></el-table-column>
        <el-table-column prop="update_time"
                         label="更新时间"></el-table-column>

        <el-table-column label="是否发布"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="goEditPage(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.current_page"
                     :page-sizes="[5, 10, 15, 20]"
                     :page-size="queryInfo.per_page"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total"
                     background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        // 搜索关键字
        keyword: '',
        // 当前页码数
        current_page: 1,
        // 每页显示数
        per_page: 10
      },
      total: 0,
      showList: []
    }
  },

  created () {
    this.getShowList()
  },

  methods: {
    async getShowList () {
      const { data: res } = await this.$http.get('article', { params: this.queryInfo })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data.data
      this.total = res.data.total
    },

    // 分页组件
    handleSizeChange (articleize) {
      this.queryInfo.per_page = articleize
      this.getShowList()
    },

    handleCurrentChange (newPage) {
      this.queryInfo.current_page = newPage
      this.getShowList()
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`article/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 删除管理员信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这条新闻吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('article/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('删除成功')
      this.getShowList()
    },

    // 路由跳转
    goAddPage () {
      this.$router.push('/article/add')
    },

    goEditPage (rowid) {
      this.$router.push(
        { path: '/article/edit/' + rowid }
      )
    }
  }
}
</script>

<style scopen>
</style>
